<template>
  <v-dialog :value="value" @input="close" max-width="800px">
    <v-card v-if="isLoaded">

      <!-- Title -->
      <v-card-title>
        <h3>{{$t('developers.users.titles.dialogEdit', {firstname: form.firstName, name: form.lastName})}}</h3>
      </v-card-title>

      <v-card-text>
        <v-row no-gutters class="mt-6">

          <!-- Email -->
          <v-col cols="12">
            <v-text-field
              :label="$t('developers.users.inputs.email')"
              outlined
              maxlength="255"
              v-model="form.email">
            </v-text-field>
          </v-col>

          <!-- Credits -->
          <v-col cols="12">
            <v-text-field
              :label="$t('developers.users.inputs.credits')"
              outlined
              type="number"
              v-model="form.credits" />
          </v-col>

          <!-- is Admin -->
          <v-col cols="12">
            <v-switch
                v-model="form.admin"
                :label="$t('developers.users.inputs.isAdmin')"/>
          </v-col>

          <!-- Services -->
          <v-col cols="12">
            <v-row no-gutters>
              <template v-for="service of services">
                <v-col cols="12">
                  <v-checkbox
                    v-model="form.authorizedServices"
                    :label="service.name"
                    :value="service.id"
                  ></v-checkbox>
                </v-col>
              </template>
            </v-row>

          </v-col>

          <v-col cols="12">
            <AlertBar />
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
          class="mx-4"
          :isLoadingBtnSave="isLoadingBtnSave"
          @submit="customSubmit"
          @cancel="close" />
      </v-card-actions>
    </v-card>

    <v-card v-else min-height="500">
      <v-row no-gutters justify="center" align="center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate>
        </v-progress-circular>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
  import dialog from "@/mixins/dialog"

  export default {

    name: "DevelopersUsersEditDialog",

    components: {

      AlertBar: () => import("@/components/Bars/AlertBar"),
      InputDatePicker: () => import("@/components/Inputs/InputDatePicker"),
      ButtonBarFormDialog: () => import("@/components/Buttons/ButtonBarFormDialog"),
    },

    mixins: [dialog],

    data() {
      return {
        defaultForm: {
          services: []
        },

        services: [],
        isLoaded: false,
        isLoadingBtnSave: false
      }
    },

    created() {
      console.log(this.item);
      this.getAllServices();

    },

    methods: {
      getAllServices() {
        this.isLoaded = false;

        this
          .$http
          .get(`/developers/services`, {
            headers: {
              Authorization: "Bearer " + this.$session.get('jwt')
            }
          })
          .then(res => {
            this.services = res.data;
            this.getAuthorizedServices();
          })
          .catch(err => {
            this.$store.commit("toast/showError", "Cannot load services");
          });
      },

      getAuthorizedServices() {

        this
          .$http
          .get(`/developers/users/${this.item.id}/services`, {
            headers: {
              Authorization: "Bearer " + this.$session.get('jwt')
            }
          })
          .then(res => {
            this.form.admin = this.form.role === 'admin';
            this.form.authorizedServices = res.data.map(el => el.id);
            this.isLoaded = true;
           // console.log(res.data);
          })
          .catch(err => {
            this.$store.commit("toast/showError", "Cannot load authorized services");
          });
      },

      customSubmit() {

        this.isLoadingBtnSave = true;

        this
          .$http
          .put(`/developers/users/${this.item.id}`, {
            email: this.form.email,
            credits: this.form.credits,
            admin: this.form.admin,
            authorizedServices: this.form.authorizedServices
          }, {
            headers: {
              Authorization: "Bearer " + this.$session.get('jwt')
            }
          })
          .then(res => {
            this.$emit("submit");
          })
          .catch(err => {
            this.$store.commit("toast/showError", "Error during update");
          })
        .finally(() => {
          this.isLoadingBtnSave = false;
        });
      }
    }
  }
</script>

<style scoped>

</style>